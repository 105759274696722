import React, { useState, useEffect } from "react";
import "./glitchImage.css";
import main from "../assets/backgrounds/L1.jpg";
import glitch1 from "../assets/backgrounds/L2.jpg";
import glitch2 from "../assets/backgrounds/L3.jpg";
import glitch3 from "../assets/backgrounds/L4.jpg";
import glitch4 from "../assets/backgrounds/L5.jpg";

const glitchImages = [glitch1, glitch2, glitch3, glitch4];

const ImageHorizon = () => {
  const [glitchIndex, setGlitchIndex] = useState(4);
  const [runGlitch, setRunGlitch] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRunGlitch((v) => {
        return v === 4 ? 0 : v + 1;
      });
    }, 3500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (runGlitch !== 0) {
      const timeouts = [
        [3, 0],
        [2, 125],
        [4, 250],
        [0, 625],
        [1, 750],
        [0, 875],
        [4, 1000],
        [0, 1125],
        [3, 1250],
        [4, 1375],
      ];

      timeouts.forEach(([index, time]) => {
        setTimeout(() => {
          setGlitchIndex(index);
        }, time);
      });
    }
  }, [runGlitch]);

  return (
    <div className="relative z-10 parentContainerHorizon xxs:mt-[130px] sm:mt-[200px] md:mt-[200px] lg:mt-[300px] xxl:mt-[400px]">
      <div className="glitch-container">
        <img className="main-image" src={main} alt="Main" />
        {glitchIndex !== 4 && (
          <div className="glitch-images">
            <img
              src={glitchImages[glitchIndex]}
              alt={`Glitch ${glitchIndex}`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageHorizon;
