import React from "react";
import { arcBlue, headlineSoon, submitEmailInactive } from "../assets";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import EmailForm from "./EmailForm";
import "./shipHover.css";
import "./CallToAction-BottomPage.css";

const ComingSoon = () => {
  return (
    <>
      {/* SMALL SCREEN */}
      <div className="xxs:block xs:hidden columns-1 items-center justify-center xxs:mt-[50px] xs:mt-[25px]">
        <div
          className=" flex justify-center items-center mt-5 z-0"
          style={{ zIndex: -1 }}
        >
          <div className="xxs:mt-[-200px] xs:mt-[-280px] sm:mt-[-330px] lg:mt-[-400px] lg2:mt-[-430px] xl:mt-[-600px] xxl:mt-[-720px]">
            <img src={arcBlue} alt="arcBlue" className="scale-125 z-0" />
          </div>
        </div>
      </div>

      {/* MID-LARGE SCREEN */}
      <div
        className=" columns-1 items-center justify-center 
    xxs:mt-[0px] xs:mt-[25px] md:mt-[100px] lg:mt-[400px] xxl:mt-[425px] xxl2:mt-[800px]"
      >
        <div className="flex flex-col items-center my-5 xs:mt-[200px] sm:mt-[300px]">
          <div className="">
            <img
              src={headlineSoon}
              alt="headlineSoon"
              className="xxs:scale-75 xxl:scale-100 "
              loading="lazy"
            />
          </div>
          <p
            className="bottom-text-container text-center justify-center items-center
                  xxs:text-[12px] xs:text-[16px] sm:text-[18px] md:text-[18px] lg:text-[18px] 
                  xl:text-[24px] xxl:text-[30px]"
          >
            The shipyard is getting stocked, and pilots are
            <br />
            reporting for duty.
            <strong> Sign up </strong>
            below to get the
            <br />
            latest updates on the
            <strong> NodeRunners Universe.</strong>
          </p>
          {/* <NewsletterSubscribe className="" /> */}
          <div className="relative">
            <img
              src={submitEmailInactive}
              alt="emailInactive"
              className="scale-75"
              loading="lazy"
            />
            {/* <div className="absolute top-1/2 xxs:left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-[0px]"> */}
            <div
              className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex justify-center"
              style={{ width: "65%" }}
            >
              <NewsletterSubscribe className="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;

const NewsletterSubscribe = () => {
  const MAILCHIMP_URL =
    "https://gmail.us21.list-manage.com/subscribe/post?u=704a962a6876a697f90284f7d&amp;id=dfa3198846&amp;f_id=004d54e1f0";

  return (
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={(props) => {
        const { subscribe, status, message } = props || {};
        return (
          <EmailForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        );
      }}
    />
  );
};
