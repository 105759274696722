import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import MainContent from "./components/MainContent";
import MainContentMobile from "./components/MainContentMobile";
import LogoLoader from "./components/LogoLoader";

const useIsomorphicEffect = () => {
  return typeof window !== "undefined" ? useLayoutEffect : useEffect;
};

function App() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const isomorphicEffect = useIsomorphicEffect();
  const [loading, setLoading] = useState(true);
  const loadTimeout = useRef();

  isomorphicEffect(() => {
    // document.documentElement.scrollTop = 0;
    // console.log("start", document.readyState);
    // setTimeout(() => setLoading(false), 3000);

    const onPageLoad = () => {
      // console.log("document loaded.");
      if (loadTimeout.current) clearTimeout(loadTimeout.current);
      document.documentElement.style.overflow = "auto";
      setLoading(false);
    };

    const onLoadTimeout = () => {
      window.removeEventListener("load", onPageLoad);
      document.documentElement.style.overflow = "auto";
      setLoading(false);
    };

    if (document.readyState === "complete") {
      loadTimeout.current = setTimeout(onLoadTimeout, 5000);
    } else {
      window.addEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <BrowserRouter>
      {loading && <LogoLoader />}
      <ParallaxProvider>
        <div className="App">
          {/* Render loading component until image is loaded */}
          {screenSize >= 460 ? <MainContent /> : <MainContentMobile />}
        </div>
      </ParallaxProvider>
    </BrowserRouter>
  );
}

export default App;
