import { useState } from "react";

const NewsletterForm = ({ message, status, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");

  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError("Please enter a valid email address");
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    clearForm();

    return email && email.indexOf("@") > -1 && isFormValidated;
  };

  const clearForm = () => {
    setEmail("");
  };

  const handleInputKeyEvent = (event) => {
    setError(null);

    if (event.key === "Enter") {
      event.preventDefault();
      handleFormSubmit();
    }
  };

  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return message;
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? formattedMessage : null;
  };

  const handleSubmitButtonClick = (event) => {
    event.preventDefault();
    handleFormSubmit();
  };
  // xxs:w-[190px] xs:w-[175px] sm:w-[250px] md:w-[300px]
  return (
    <div className="flex justify-center" style={{ width: "100%" }}>
      <div className="emailInput flex w-full">
        <input
          id="emailInput"
          onChange={(event) => setEmail(event?.target?.value ?? "")}
          type="email"
          placeholder={error || getMessage(message) || "Your email"}
          className="appearance-none block
            text-pink-400 focus:outline-none 
            xxs:text-[10px] xs:text-[14px] sm:text-[18px] lg:text-[18px]"
          style={{
            backgroundColor: "transparent",
            flex: 5,
          }}
          onKeyUp={(event) => handleInputKeyEvent(event)}
          value={email}
        />
        <button
          className="xxs:text-[10px] xs:text-[14px] lg:text-[18px]"
          onClick={handleSubmitButtonClick}
          style={{ color: "transparent", flex: 1 }}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};
// color: "transparent",
export default NewsletterForm;
