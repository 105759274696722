import React, { useEffect, useState } from "react";
import { arcPurp } from "../assets";
import { Parallax } from "react-scroll-parallax";
import titan from "../assets/hero/Titan.gif";
import tagline from "../assets/hero/mobile-tagline.png";
import "./hero.css";

// const LazyGlobeHero = React.lazy(() => import("./Globe"));

const Hero = () => {
  // const [isLoaded, setIsLoaded] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="columns-1 z-0 justify-center items-center">
      <div className="relative  ">
        <div
          className="relative mt-[25px]"
          style={{
            height: screenSize >= 768 ? "750px" : "200px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={titan} alt="Description of GIF" />
        </div>

        {/* SMALL SCREEN PARALLAX */}
        <Parallax translateY={[0, 30]} className="md:hidden">
          <div className="justify-center flex z-20 mt-[-40px]">
            <img
              src={tagline}
              alt="arc"
              className="game xxs:scale-65"
              id="gameDesktop"
            />
          </div>
          <div className="flex justify-center items-center z-0 ">
            <img
              src={arcPurp}
              alt="arc"
              className="scale-150 absolute z-[-1] xs:mt-[-30px] sm:mt-[-40px] md:mt-[300px] lg:mt-[850px] xl:mt-[-160px]"
            />
          </div>
          <div className="relative flex-col items-center z-20">
            <div className="hero-text-container">
              <p className="text-center mx-10 mt-[50px] z-30 xxs:text-[14px] xs:text-[15px] sm:text-[15px] md:text-[16px] lg:text-[18px] xl:text-[24px] sm:py-6 sm:mb-[-40px] lg:text-xl">
                NodeRunners is a space-themed{" "}
                <span style={{ whiteSpace: "nowrap" }}>role-playing</span> game
                accessible from any browser.
              </p>
              <p className="text-center mx-10 mt-[15px] z-30 xxs:text-[14px] xs:text-[15px] sm:text-[15px] md:text-[16px] lg:text-[18px] xl:text-[24px] sm:py-6 sm:mb-[-40px] lg:text-xl">
                Our guild-centric design emphasizes strong social dynamics and
                strategic gameplay.
              </p>
              <p className="text-center mx-10 mt-[15px] z-30 xxs:text-[14px] xs:text-[15px] sm:text-[15px] md:text-[16px] lg:text-[18px] xl:text-[24px] sm:py-6 sm:mb-[-40px] lg:text-xl">
                Players will acquire a ship, join a guild, and battle for
                economic supremacy across the NodeRunners universe.
              </p>
            </div>
          </div>
        </Parallax>
        {/* MID-LARGE SCREEN PARALLAX */}
        {/* <Parallax translateY={[0, 40]} className="hidden md:block ">
          <div
            className="flex justify-center mt-[-150px] relative z-20 "
            id="gameDesktop"
          >
            <img
              src={headlineInterStellar}
              alt="arc"
              className="xxs:scale-75"
            />
          </div>
          <div className="flex justify-center items-center z-0 ">
            <img
              src={arcPurp}
              alt="arc"
              className="scale-150 absolute z-0 xxs:mt-[1100px] xs:mt-[425px] sm:mt-[350px] md:mt-[-75px] lg:mt-[-80px] xl:mt-[-90px]"
            />
          </div>
          <div className="relative flex-col z-20 mt-[100px]">
            <div className="hero-text-container ">
              <p className="text-center z-30 xxs:text-[14px] xs:text-[15px] sm:text-[15px] md:text-[16px] lg:text-[18px] xl:text-[24px]">
                NodeRunners is a space-themed metaverse role-playing
                <br /> game (meta-RPG) accessible from any browser.
              </p>
              <p className="text-center z-30 xxs:text-[14px] xs:text-[15px] sm:text-[15px] md:text-[16px] lg:text-[18px] xl:text-[24px]">
                <br />
                Our guild-centric game design emphasizes strong
                <br /> social dynamics and strategic gameplay.
              </p>
              <p className="text-center z-30  xxs:text-[14px] xs:text-[15px] sm:text-[15px] md:text-[16px] lg:text-[18px] xl:text-[24px] py-6">
                Players will acquire a ship, join a guild, and battle for
                <br /> economic supremacy across the NodeRunners Universe.
              </p>
            </div>
          </div>
        </Parallax> */}
      </div>
    </div>
  );
};

export default Hero;
