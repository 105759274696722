import React, { useEffect, useState } from "react";
import titan from "../assets/hero/Titan.gif";
import guardian from "../assets/hero/Guardian.gif";
import red_predator from "../assets/hero/red-predator.gif";
import green_predator from "../assets/hero/green-predator.gif";
import tagline from "../assets/hero/tagline.png";
import "./hero.css";

const Hero = () => {
  // const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    // const handleResize = () => {
    //   setScreenSize(window.innerWidth);
    // };

    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    // window.addEventListener("resize", handleResize);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      // window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const calculateMovement = (imagePosition) => {
    // Logic to calculate movement based on mouse position and image position
    // This is a simple example. You might need a more complex calculation.
    const distanceX = (mousePosition.x - imagePosition.x) * 0.03;
    const distanceY = (mousePosition.y - imagePosition.y) * 0.03;

    return {
      transform: `translate(${distanceX}px, ${distanceY}px)`,
    };
  };

  const calculateMovement2 = (imagePosition) => {
    // Logic to calculate movement based on mouse position and image position
    // This is a simple example. You might need a more complex calculation.
    const distanceX = (-mousePosition.x + imagePosition.x) * 0.03;
    const distanceY = (-mousePosition.y + imagePosition.y) * 0.03;

    return {
      transform: `translate(${distanceX}px, ${distanceY}px)`,
    };
  };

  return (
    <div className="columns-1 z-0 justify-center items-center xs:mt-[-50px] md:mt-[-80px] lg:mt-[-70px] xl:mt-[-100px]">
      <div
        className="relative"
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          className=" "
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            className="xs:w-[22%] xs:ml-[-50px] xs:mt-[100px] sm:w-[25%] sm:ml-[-90px] md:w-[35%] md:mt-[150px] md:ml-[-100px]
            lg:w-[30%] lg:mt-[150px] lg:ml-[-170px] xl:w-[40%] xl:mt-[150px] xl:ml-[-170px]"
            src={red_predator}
            alt="red_predator"
            style={{
              ...calculateMovement({ x: 200, y: 400 }),
            }}
          />
        </div>
        <div
          className="relative "
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            className="xs:w-[22%] xs:mt-[50px] sm:mt-[-20px] md:mt-[-50px] sm:w-[25%] md:w-[30%] lg:w-[30%] lg:ml-[60px] xl:w-[40%] xl:ml-[30px]"
            src={guardian}
            alt="guardian"
            style={{
              ...calculateMovement2({ x: -100, y: -300 }),
            }}
          />
        </div>
        <div
          className=" "
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            className="xs:w-[22%] xs:mt-[100px] sm:w-[25%] md:w-[30%] md:mt-[150px] lg:w-[30%] lg:mt-[100px] xl:w-[30%] xl:mt-[100px]"
            src={green_predator}
            alt="green_predator"
            style={{
              ...calculateMovement({ x: 200, y: 400 }),
            }}
          />
        </div>
      </div>
      <div
        className="relative"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={titan}
          alt="titan"
          className="xs:w-[50%] sm:w-[55%] md:w-[60%] lg:h-[100%] xl:h-[100%]"
        />
      </div>
      {/* <Parallax translateY={[0, 60]} className="hidden md:block "> */}
      <div
        className="relative z-20 flex justify-center 
        xs:mt-[100px] xxl1:mt-[200px] 
        xs:mb-[150px] xxl1:mb-[350px] "
        id="gameDesktop"
      >
        <img src={tagline} alt="arc" className="xxs:scale-75 xxl1:scale-100" />
      </div>
      {/* </Parallax> */}
    </div>
  );
};

export default Hero;
