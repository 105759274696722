import React, { useState } from "react";
import header from "../assets/partnersSection/partners-header.png";
import music_mainnet_logo from "../assets/partnersSection/music-mainnet-logo-small.png";
import td_logo from "../assets/partnersSection/td-logo.png";
import td_logo_color from "../assets/partnersSection/thinkdiffusion-logo-color.svg";
import neotokyo_logo from "../assets/partnersSection/neotokyo_logo.png";
import nxg_logo from "../assets/partnersSection/nxg_logo.png";

const PartnersSection = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  return (
    <div className="xxs:mt-[20px]">
      <div className="flex justify-center align-items">
        <img
          src={header}
          alt="your-team"
          className="xxs:scale-65 xs:scale-65 xxl:scale-100"
          id="yourTeamDesktop"
        />
      </div>
      <div className="flex justify-center align-items z-100">
        <div className="flex flex-col justify-center align-items px-[30px]">
          <a
            href="https://www.musicmainnet.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <img
                className="xxs:w-[100px] sm:w-[200px]"
                src={music_mainnet_logo}
                alt="your-team"
                id="yourTeamDesktop"
              />
            </div>
          </a>
        </div>

        <div className="flex flex-col justify-center align-items px-[30px]">
          <a
            href="https://www.thinkdiffusion.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex justify-center ">
              <img
                className="xxs:w-[50px] xs:w-[60px] sm:w-[100px] md:w-[100px]"
                src={td_logo}
                alt="your-team"
                id="yourTeamDesktop"
              />
            </div>
            <div>
              <img
                className="xxs:h-[15px] xs:h-[15px] sm:h-[25px]  md:h-[25px] mt-[13px]"
                src={td_logo_color}
                alt="your-team"
                id="yourTeamDesktop"
              />
            </div>
          </a>
        </div>

        {screenSize > 850 && (
          <div className="flex flex-col justify-center align-items px-[30px]">
            <a
              href="https://neotokyo.codes/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img
                  className="xxs:w-[100px] sm:w-[200px]"
                  src={neotokyo_logo}
                  alt="your-team"
                  id="yourTeamDesktop"
                />
              </div>
            </a>
          </div>
        )}

        {screenSize > 850 && (
          <div className="flex flex-col justify-center align-items px-[15px]">
            <a
              href="https://playnxg.gg/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img
                  className="xxs:w-[100px] sm:w-[200px]"
                  src={nxg_logo}
                  alt="your-team"
                  id="yourTeamDesktop"
                />
              </div>
            </a>
          </div>
        )}
      </div>

      {screenSize <= 850 && (
        <div className="flex justify-center align-items z-100 mt-[30px]">
          <div className="flex flex-col justify-center align-items px-[30px]">
            <a
              href="https://neotokyo.codes/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img
                  className="xxs:w-[100px] sm:w-[200px]"
                  src={neotokyo_logo}
                  alt="your-team"
                  id="yourTeamDesktop"
                />
              </div>
            </a>
          </div>

          <div className="flex flex-col justify-center align-items xxs:px-[30px] sm:px-[15px]">
            <a
              href="https://playnxg.gg/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img
                  className="xxs:w-[100px] sm:w-[200px]"
                  src={nxg_logo}
                  alt="your-team"
                  id="yourTeamDesktop"
                />
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnersSection;
