import React from "react";
import {
  DNA,
  face,
  pilotHero,
  dividerBottom,
  dividerTop,
  headlinePilots,
} from "../assets";
import "./glitchImagePilot.css";
import { Parallax } from "react-scroll-parallax";
import "./pilots.css";
import vid from "../assets/glitchVideos/pilot-final.mp4";

const Pilots = () => {
  return (
    <div className="xs:mt-[200px]">
      <Parallax translateY={[0, 30]} className="relative z-50">
        <div>
          <img
            src={headlinePilots}
            alt="pilots"
            className="xxs:scale-75 relative z-50 "
            loading="lazy"
          />
        </div>
        <div className="">
          <img
            src={dividerTop}
            alt="pilots"
            className="scale-200"
            loading="lazy"
          />
        </div>
        <div className="flex justify-center align-center z-50 relative mx-10 ">
          <div className="flex row justify-evenly items-center ">
            <div className="flex items-center z-20 ">
              <img src={DNA} alt="DNA" className="" loading="lazy" />
            </div>
          </div>
          <div className="flex items-center z-20 ">
            <img src={face} alt="face" className="" loading="lazy" />
          </div>
        </div>
        <div className="pilot-paragraph-container">
          <p className="pilot-text-container z-30 mx-[50px] mt-5 mb-5 text-sm xxs:text-[14px] xs:text-[16px] sm:text-[12px] md:text-[16px] lg:text-[18px] xl:text-[24px] xs:hidden xxs:block">
            Genesis pilots consist of five origins:
            <br />
            <strong>
              {" "}
              Baleforged, Nyx, Abyssians, Eryines, and Illuminated.
            </strong>
            <br />
            <br />
            Each pilot’s origin correlates with a unique stat boost to every
            ship under that pilot’s control.
          </p>
        </div>
        <div>
          <img
            src={dividerBottom}
            alt="dividerBottom"
            className="scale-200"
            loading="lazy"
          />
        </div>
      </Parallax>
      <div className="flex relative justify-center z-10 items-center scale-400 xxs:mt-[380px] md:mt-[225px] lg:mt-[350px] lg2:mt-[500px] ml-[15px] ">
        <video autoPlay loop muted playsInline>
          <source src={vid} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Pilots;
