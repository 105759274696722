import "../App.css";
import {
  MyParticles,
  Navbar,
  Footer,
  ImageHorizon,
  PilotCards,
  Pilots,
  Ships,
  ShipsHero,
  ConceptSection,
} from "./index.js";
import Hero from "./Hero2.jsx";
import vid from "../assets/backgrounds/space-loop.mp4";
import arc_purple from "../assets/hero/arc-purple.png";
import arc_purple_wide from "../assets/hero/arc-purple-wide2.png";
import arc_blue from "../assets/arcs/arc-shallow-blue.png";
import { useEffect } from "react";
import ComingSoon from "./ComingSoon.jsx";
import YourTeamSection from "./YourTeamSection.jsx";
import PartnersSection from "./PartnersSection.jsx";

const MainContent = () => {
  useEffect(() => {
    let canvas = document.getElementById("arcCanvas");
    if (canvas) {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      let ctx = canvas.getContext("2d");

      let width = canvas.width;
      let height = canvas.height;

      ctx.beginPath();
      ctx.moveTo(0, height);
      // Create a concave arc
      ctx.bezierCurveTo(width / 4, 0, (3 * width) / 4, 0, width, height);
      ctx.lineTo(0, height);
      ctx.fillStyle = "rgba(0, 0, 0, 1)"; // Set to the color of your choice
      ctx.fill();
    }
  }, []);

  return (
    <div>
      <MyParticles />
      <div className="video-section">
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            top: 0,
            left: 0,
            zIndex: 0,
          }}
        >
          <source src={vid} type="video/mp4" />
        </video>
        <canvas
          id="arcCanvas"
          style={{ position: "absolute", bottom: 0, left: 0 }}
        ></canvas>
        <img
          src={arc_purple}
          alt="arc"
          className="arc-image xxl:hidden xs:block"
        />
        <img
          src={arc_purple_wide}
          alt="arc"
          className="arc-image-wide xs:hidden xxl:block"
        />
        <div className="content-container">
          <Navbar />

          {/* Banner */}
          <div
            className="info-banner xxs:mt-[14px] xs:mt-[13px] sm:mt-[18px] 
            md:mt-[20px] lg:mt-[24px] xl:mt-[30px] xxl:mt-[40px] xxs:text-[14px] xs:text-[13px] sm:text-[18px] 
          md:text-[20px] lg:text-[24px] xl:text-[30px] xxl:text-[40px] z-10 w-[80%]"
          >
            <div
              className="banner-text-container xxs:text-[14px] xs:text-[13px] sm:text-[18px] 
              md:text-[20px] lg:text-[24px] xl:text-[30px] xxl:text-[40px]"
            >
              <p>We're minted out. Join our Discord to stay up to date.</p>
            </div>
          </div>

          <div className="xs:mt-[100px] sm:mt-[150px] md:mt-[170px] lg:mt-[170px] xl:mt-[200px]">
            <Hero />
          </div>
        </div>
      </div>
      <div
        className="relative flex-col z-20 mt-[100px]"
        style={{ zIndex: 300 }}
      >
        <div
          className="hero-text-container xxs:text-[14px] xs:text-[15px] sm:text-[15px] 
          md:text-[16px] lg:text-[18px] xl:text-[24px] xxl:text-[30px]"
        >
          <p className="text-center z-30 ">
            NodeRunners is a space-themed role-playing game
            <br /> accessible from any browser.
          </p>
          <p className="text-center z-30 py-4">
            <br />
            Our guild-centric design emphasizes strong social
            <br />
            dynamics and strategic gameplay.
          </p>
          <p className="text-center z-30 py-8">
            Players will acquire a ship, join a guild, and battle for
            <br /> economic supremacy across the NodeRunners
            <br /> universe.
          </p>
        </div>
      </div>
      <div className="relative content-container" style={{ zIndex: 300 }}>
        <ConceptSection />
      </div>
      <div
        className="relative justify-center align-center min-w-[360px]"
        style={{ zIndex: 0 }}
      >
        <div className="z-1 content-container">
          <ImageHorizon />
        </div>
      </div>
      <div className="z-40 " style={{ overflow: "hidden" }}>
        <div className="z-20 content-container">
          <div className="hidden xs:block z-50" id="pilotsDesktop">
            <Pilots />
          </div>
          <div className="hidden xs:block z-30 ">
            <PilotCards />
          </div>
          <div className="" id="ships">
            <ShipsHero />
            <div className="hidden xs:block">
              <Ships />
            </div>
          </div>
          <div className="mt-[100px]" id="team">
            <YourTeamSection />
          </div>
          <div className="mt-[100px]">
            <PartnersSection />
          </div>
        </div>

        <div className="hidden xs:block video-section mt-[350px] z-0">
          <img src={arc_blue} alt="arc" className="arc-blue-image" />
        </div>
        <div className="content-container">
          <ComingSoon />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MainContent;
