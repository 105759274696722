import { useEffect } from "react";
import {
  ship1,
  ship2,
  ship3,
  ship4,
  ship5,
  ship6,
  ship7,
  ship8,
} from "../assets";
import "./shipHover.css";

// import './spotlight.css';

const Ships = () => {
  // const [shipOpacity, setShipOpacity] = useState(0.2);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const imageElements = document.querySelectorAll(".ship-image");

      imageElements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        const imageCenter = rect.top + rect.height / 2;
        const isCentered =
          imageCenter >= windowHeight / 2 - rect.height / 2 &&
          imageCenter <= windowHeight / 2 + rect.height / 2;
        element.style.opacity = isCentered ? 1 : 0.15;
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  // const handleMouseEnter = () => {
  //   setShipOpacity(1);
  // };

  // const handleMouseLeave = () => {
  //   setShipOpacity(0.2);
  // };

  const shipData = [
    { id: 1, image: ship1 },
    { id: 2, image: ship2 },
    { id: 3, image: ship3 },
    { id: 4, image: ship4 },
    { id: 5, image: ship5 },
    { id: 6, image: ship6 },
    { id: 7, image: ship7 },
    { id: 8, image: ship8 },
  ];

  return (
    <div className="columns-1 items-center justify-center xxs:mt-[50px] xs:mt-[25px]">
      <div className="z-20 relative">
        {shipData.map((ship) => (
          <div key={ship.id}>
            <img
              src={ship.image}
              alt="shipImage"
              className="flex justify-center items-center image-container ship-image transition-opacity z-20"
              // loading="lazy"
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ships;
