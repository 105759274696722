import React from "react";
import { arcBlue, headlineSoon, submitEmailInactive } from "../assets";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useState } from "react";
import {
  ship1,
  ship2,
  ship3,
  ship4,
  ship5,
  ship6,
  ship7,
  ship8,
} from "../assets";
import EmailForm from "./EmailForm";
import "./shipHover.css";
import "./CallToAction-BottomPage.css";

const Ships = () => {
  const [hoveredShip1, setHoveredShip1] = useState(false);
  const [hoveredShip2, setHoveredShip2] = useState(false);
  const [hoveredShip3, setHoveredShip3] = useState(false);
  const [hoveredShip4, setHoveredShip4] = useState(false);
  const [hoveredShip5, setHoveredShip5] = useState(false);
  const [hoveredShip6, setHoveredShip6] = useState(false);
  const [hoveredShip7, setHoveredShip7] = useState(false);
  const [hoveredShip8, setHoveredShip8] = useState(false);

  const handleHover1 = () => {
    setHoveredShip1(true);
  };
  const handleLeave1 = () => {
    setHoveredShip1(false);
  };
  const handleHover2 = () => {
    setHoveredShip2(true);
  };
  const handleLeave2 = () => {
    setHoveredShip2(false);
  };
  const handleHover3 = () => {
    setHoveredShip3(true);
  };
  const handleLeave3 = () => {
    setHoveredShip3(false);
  };
  const handleHover4 = () => {
    setHoveredShip4(true);
  };
  const handleLeave4 = () => {
    setHoveredShip4(false);
  };
  const handleHover5 = () => {
    setHoveredShip5(true);
  };
  const handleLeave5 = () => {
    setHoveredShip5(false);
  };
  const handleHover6 = () => {
    setHoveredShip6(true);
  };
  const handleLeave6 = () => {
    setHoveredShip6(false);
  };
  const handleHover7 = () => {
    setHoveredShip7(true);
  };
  const handleLeave7 = () => {
    setHoveredShip7(false);
  };
  const handleHover8 = () => {
    setHoveredShip8(true);
  };
  const handleLeave8 = () => {
    setHoveredShip8(false);
  };

  const opacityClass1 = hoveredShip1 ? "opacity-100" : "opacity-20";
  const opacityClass2 = hoveredShip2 ? "opacity-100" : "opacity-20";
  const opacityClass3 = hoveredShip3 ? "opacity-100" : "opacity-20";
  const opacityClass4 = hoveredShip4 ? "opacity-100" : "opacity-20";
  const opacityClass5 = hoveredShip5 ? "opacity-100" : "opacity-20";
  const opacityClass6 = hoveredShip6 ? "opacity-100" : "opacity-20";
  const opacityClass7 = hoveredShip7 ? "opacity-100" : "opacity-20";
  const opacityClass8 = hoveredShip8 ? "opacity-100" : "opacity-20";

  return (
    <div
      className="columns-1 items-center justify-center 
    xxs:mt-[175px] xs:mt-[25px] md:mt-[100px] lg:mt-[100px] xxl:mt-[225px]"
    >
      <div className="flex flex-col items-center" style={{ zIndex: 300 }}>
        <div>
          <img
            src={ship1}
            alt="ship1"
            className={`flex justify-center items-center image-container ${opacityClass1} transition-opacity `}
            onMouseEnter={handleHover1}
            onMouseLeave={handleLeave1}
            loading="lazy"
            style={{ width: "100vw" }}
          />
        </div>
        <div>
          <img
            src={ship2}
            alt="ship2"
            className={`flex justify-center items-center image-container ${opacityClass2} transition-opacity `}
            onMouseEnter={handleHover2}
            onMouseLeave={handleLeave2}
            loading="lazy"
            style={{ width: "100vw" }}
          />
        </div>
        <div>
          <img
            src={ship3}
            alt="ship3"
            className={`flex justify-center items-center image-container ${opacityClass3} transition-opacity `}
            onMouseEnter={handleHover3}
            onMouseLeave={handleLeave3}
            loading="lazy"
            style={{ width: "100vw" }}
          />
        </div>
        <div>
          <img
            src={ship4}
            alt="ship4"
            className={`flex justify-center items-center image-container ${opacityClass4} transition-opacity `}
            onMouseEnter={handleHover4}
            onMouseLeave={handleLeave4}
            loading="lazy"
            style={{ width: "100vw" }}
          />
        </div>
        <div>
          <img
            src={ship5}
            alt="ship5"
            className={`flex justify-center items-center image-container ${opacityClass5} transition-opacity `}
            onMouseEnter={handleHover5}
            onMouseLeave={handleLeave5}
            loading="lazy"
            style={{ width: "100vw" }}
          />
        </div>
        <div>
          <img
            src={ship6}
            alt="ship6"
            className={`flex justify-center items-center image-container ${opacityClass6} transition-opacity`}
            onMouseEnter={handleHover6}
            onMouseLeave={handleLeave6}
            loading="lazy"
            style={{ width: "100vw" }}
          />
        </div>
        <div>
          <img
            src={ship7}
            alt="ship7"
            className={`flex justify-center items-center image-container ${opacityClass7} transition-opacity`}
            onMouseEnter={handleHover7}
            onMouseLeave={handleLeave7}
            loading="lazy"
            style={{ width: "100vw" }}
          />
        </div>
        <div>
          <img
            src={ship8}
            alt="ship8"
            className={`flex justify-center items-center image-container ${opacityClass8} transition-opacity `}
            onMouseEnter={handleHover8}
            onMouseLeave={handleLeave8}
            loading="lazy"
            style={{ width: "100vw" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Ships;
