import { useState, useRef, useEffect } from "react";
import {
  discordHover,
  discord,
  medium,
  mediumHover,
  twitter,
  twitterHover,
  gitbook,
  gitbookHover,
  condensedLogo,
  gameHover,
  game,
  pilotsHover,
  pilots,
  shipsHover,
  ships,
  team,
  teamHover,
  lore,
  loreHover,
  soundAllOn,
  soundAllOnHover,
  soundAllOff,
  soundAllOffHover,
} from "../assets";
import { Link } from "react-scroll";
import musicFile from "../assets/music/backgroundMusic.mp3";
import logo from "../assets/menuButtonsAndStates/nr-logo-transparent.png";
import { ModalSlides, ModalStories } from "./Modal.jsx";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const importAll = (requireContext) => {
  return requireContext.keys().map(requireContext);
};

const images = importAll(
  require.context("../assets/comics/covers", false, /\.(jpe?g)$/)
);

const imagesAct1 = importAll(
  require.context("../assets/comics/Act1", false, /\.(jpe?g)$/)
);

const Navbar = () => {
  const [sound, setSound] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const carouselRef = useRef(null);

  // Refocus the carousel when the modal closes
  useEffect(() => {
    if (!selectedSlide) {
      carouselRef.current?.forceFocus();
    }
  }, [selectedSlide]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsOpenModal(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSound = () => {
    setSound(!sound);
  };

  const handleMouseEnterGame = (event) => {
    event.target.src = gameHover;
  };
  const handleMouseLeaveGame = (event) => {
    event.target.src = game;
  };
  const handleMouseEnterPilots = (event) => {
    event.target.src = pilotsHover;
  };
  const handleMouseLeavePilots = (event) => {
    event.target.src = pilots;
  };
  const handleMouseEnterShips = (event) => {
    event.target.src = shipsHover;
  };
  const handleMouseLeaveShips = (event) => {
    event.target.src = ships;
  };
  const handleMouseEnterTeam = (event) => {
    event.target.src = teamHover;
  };
  const handleMouseLeaveTeam = (event) => {
    event.target.src = team;
  };
  const handleMouseEnterLore = (event) => {
    event.target.src = loreHover;
  };
  const handleMouseLeaveLore = (event) => {
    event.target.src = lore;
  };
  const handleMouseEnterTwitter = (event) => {
    event.target.src = twitterHover;
  };
  const handleMouseLeaveTwitter = (event) => {
    event.target.src = twitter;
  };

  const handleMouseEnterDiscord = (event) => {
    event.target.src = discordHover;
  };
  const handleMouseLeaveDiscord = (event) => {
    event.target.src = discord;
  };

  const handleMouseEnterGitbook = (event) => {
    event.target.src = gitbookHover;
  };
  const handleMouseLeaveGitbook = (event) => {
    event.target.src = gitbook;
  };

  const handleMouseEnterMedium = (event) => {
    event.target.src = mediumHover;
  };
  const handleMouseLeaveMedium = (event) => {
    event.target.src = medium;
  };

  const handleMouseEnterSoundAllOn = (event) => {
    event.target.src = soundAllOnHover;
  };
  const handleMouseLeaveSoundAllOn = (event) => {
    event.target.src = soundAllOn;
  };

  const handleMouseEnterSoundNoteOff = (event) => {
    event.target.src = soundAllOffHover;
  };
  const handleMouseLeaveSoundNoteOff = (event) => {
    event.target.src = soundAllOff;
  };

  // xxs:w-[180px] xs:w-[100px] sm:w-[90px] md:w-[110px] sm:mt-[5px] md:mt-[10px] lg:w-[130px]
  //               lg:mt-[15px] lg2:w-[200px] xl:w-[400px] lg2:mt-[35px] xxl1:w-[270px] xxl2:w-[450px]

  return (
    <>
      <nav
        className="relative flex justify-center items-center"
        style={{ zIndex: 300 }}
      >
        {sound && <audio src={musicFile} type="audio/wav" loop autoPlay />}
        <div
          //   className="xs:mr-[80px] sm:mr-[100px] md:mr-[100px] lg2:mr-[200px] xxl2:mr-[300px] xxs:w-[70px] xs:w-[100px]
          //  xxs:mt-[-10px] sm:w-[120px] md:w-[140px] lg:w-[175px] lg2:w-[350px] xxl1:w-[450px] xxl2:w-[450px] "
          style={{
            display: "flex", // Enable Flexbox
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            flex: 0.7,
          }}
        >
          <img src={logo} alt="logo" style={{ width: "50%" }} />
        </div>

        <div
          className="justify-center items-center"
          style={{
            flex: 1.25,
            display: "flex", // Enable Flexbox
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          <ul className="flex xxs:py-5">
            <li>
              <Link
                to="gameDesktop"
                spy={true}
                smooth={true}
                offset={850}
                duration={500}
              >
                <img
                  src={game}
                  // className="xxs:w-[180px] xs:w-[100px] sm:w-[90px] md:w-[110px] sm:mt-[5px] md:mt-[10px] lg:w-[130px]
                  // lg:mt-[15px] lg2:w-[200px] xl:w-[400px] lg2:mt-[35px] xxl1:w-[270px] xxl2:w-[450px]"
                  alt="game"
                  onMouseEnter={handleMouseEnterGame}
                  onMouseLeave={handleMouseLeaveGame}
                  // onClick={handleClickGame}
                  style={{ cursor: "pointer", width: "100%" }}
                />
              </Link>
            </li>
            <li className="">
              <Link
                to="pilotsDesktop"
                spy={true}
                smooth={true}
                offset={150}
                duration={500}
              >
                <img
                  src={pilots}
                  alt="pilots"
                  // className="xxs:w-[120px] sm:w-[108px] sm:mt-[5px] md:w-[130px] md:mt-[10px] lg:w-[152px] lg:mt-[15px]
                  // lg2:w-[240px] lg2:mt-[35px] xl:w-[400px] xxl1:w-[320px] xxl2:w-[530px]"
                  onMouseEnter={handleMouseEnterPilots}
                  onMouseLeave={handleMouseLeavePilots}
                  // onClick={handleClickPilots}
                  style={{ cursor: "pointer", width: "100%" }}
                />
              </Link>
            </li>
            <li>
              <Link
                to="ships"
                spy={true}
                smooth={true}
                offset={50}
                duration={750}
              >
                <img
                  src={ships}
                  alt="ships"
                  // className="xxs:w-[100px] sm:w-[90px] sm:mt-[5px] md:w-[110px] md:mt-[10px] lg:w-[130px] lg:mt-[15px]
                  // lg2:w-[200px] lg2:mt-[35px] xl:w-[400px] xxl1:w-[270px] xxl2:w-[450px]"
                  onMouseEnter={handleMouseEnterShips}
                  onMouseLeave={handleMouseLeaveShips}
                  // onClick={handleClickShips}
                  style={{ cursor: "pointer", width: "100%" }}
                />
              </Link>
            </li>
            <li>
              <Link
                to="team"
                spy={true}
                smooth={true}
                offset={50}
                duration={750}
              >
                <img
                  src={team}
                  alt="team"
                  // className="xxs:w-[100px] sm:w-[90px] sm:mt-[5px] md:w-[110px] md:mt-[10px] lg:w-[130px] lg:mt-[15px]
                  // lg2:w-[200px] lg2:mt-[35px] xl:w-[400px] xxl1:w-[270px] xxl2:w-[450px]"
                  onMouseEnter={handleMouseEnterTeam}
                  onMouseLeave={handleMouseLeaveTeam}
                  // onClick={handleClickShips}
                  style={{ cursor: "pointer", width: "100%" }}
                />
              </Link>
            </li>
            <li>
              <Link
                to="lore"
                spy={true}
                smooth={true}
                offset={50}
                duration={750}
              >
                <img
                  src={lore}
                  alt="lore"
                  onMouseEnter={handleMouseEnterLore}
                  onMouseLeave={handleMouseLeaveLore}
                  onClick={() => setIsOpenModal(true)}
                  style={{ cursor: "pointer", width: "100%" }}
                />
              </Link>
            </li>
          </ul>
        </div>

        <div
          //   className="py-5 xs:ml-[80px] sm:ml-[100px] md:ml-[100px] lg2:ml-[200px] xl:ml-[200px] xxl2:ml-[300px] xxs:w-[70px]
          // xs:w-[100px] xxs:mt-[-10px] sm:w-[120px] md:w-[140px] lg:w-[175px] lg2:w-[250px]"
          style={{
            display: "flex", // Enable Flexbox
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            flex: 0.7,
            width: "100%",
          }}
        >
          <div className="flex">
            <a
              href="https://twitter.com/noderunnersgg"
              target="_blank"
              rel="noopener noreferrer"
              // style={{ width: "100%" }}
            >
              <img
                src={twitter}
                alt="twitter"
                className="xs:w-[20px] sm:w-[25px] md:w-[30px] lg:w-[40px] xl:w-[50px] xxl:w-[70px]"
                onMouseEnter={handleMouseEnterTwitter}
                onMouseLeave={handleMouseLeaveTwitter}
                style={{ cursor: "pointer" }}
              />
            </a>
            <a
              href="https://medium.com/@NodeRunnersgg"
              target="_blank"
              rel="noopener noreferrer"
              // style={{ width: "100%" }}
            >
              <img
                src={medium}
                alt="medium"
                className="xs:w-[20px] sm:w-[25px] md:w-[30px] lg:w-[40px] xl:w-[50px] xxl:w-[70px]"
                onMouseEnter={handleMouseEnterMedium}
                onMouseLeave={handleMouseLeaveMedium}
                style={{ cursor: "pointer" }}
              />
            </a>
            <a
              href="https://discord.gg/noderunners"
              target="_blank"
              rel="noopener noreferrer"
              // style={{ width: "100%" }}
            >
              <img
                src={discord}
                alt="discord"
                className="xs:w-[20px] sm:w-[25px] md:w-[30px] lg:w-[40px] xl:w-[50px] xxl:w-[70px]"
                onMouseEnter={handleMouseEnterDiscord}
                onMouseLeave={handleMouseLeaveDiscord}
                style={{ cursor: "pointer" }}
              />
            </a>
            <a
              href="https://noderunners.gitbook.io/litepaper"
              target="_blank"
              rel="noopener noreferrer"
              // style={{ width: "100%" }}
            >
              <img
                src={gitbook}
                alt="gitbook"
                className="xs:w-[20px] sm:w-[25px] md:w-[30px] lg:w-[40px] xl:w-[50px] xxl:w-[70px]"
                onMouseEnter={handleMouseEnterGitbook}
                onMouseLeave={handleMouseLeaveGitbook}
                style={{ cursor: "pointer" }}
              />
            </a>

            {!sound ? (
              <>
                <img
                  src={soundAllOff}
                  alt="soundAllOff"
                  className="xs:w-[35px] xs:h-[20px] sm:w-[40px] sm:h-[25px] md:w-[40px] md:h-[30px]
                lg:w-[60px] lg:h-[40px] xl:w-[80px] xl:h-[50px] xxl:w-[100px] xxl:h-[70px]"
                  onMouseEnter={handleMouseEnterSoundNoteOff}
                  onMouseLeave={handleMouseLeaveSoundNoteOff}
                  style={{ cursor: "pointer" }}
                  onClick={handleSound}
                />
              </>
            ) : (
              <>
                <img
                  src={soundAllOn}
                  alt="soundAllOn"
                  className="xs:w-[35px] xs:h-[20px] sm:w-[40px] sm:h-[25px] md:w-[40px] md:h-[30px]
                lg:w-[60px] lg:h-[40px] xl:w-[80px] xl:h-[50px] xxl:w-[100px] xxl:h-[70px]"
                  onMouseEnter={handleMouseEnterSoundAllOn}
                  onMouseLeave={handleMouseLeaveSoundAllOn}
                  style={{ cursor: "pointer" }}
                  onClick={handleSound}
                />
              </>
            )}
          </div>
        </div>
      </nav>

      <ModalStories
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          setSelectedImage(false);
        }}
      >
        <div
          style={{
            maxWidth: "1000px",
          }}
        >
          <Carousel
            useKeyboardArrows={true}
            swipeable={true}
            showThumbs={false}
            showStatus={false}
            emulateTouch={true}
            autoFocus={true}
            ref={carouselRef} // Assign the reference to refocus
          >
            {!selectedImage
              ? images.map((img, idx) => (
                  <div
                    style={{ cursor: "pointer" }}
                    key={idx}
                    onClick={() => setSelectedImage(img)}
                  >
                    <img
                      src={img}
                      alt={`Slide ${idx}`}
                      style={{
                        height: "100vh",
                        width: "auto",
                      }}
                    />
                  </div>
                ))
              : imagesAct1.slice(1).map((img, idx) => (
                  <div
                    style={{ cursor: "pointer" }}
                    key={idx}
                    onClick={() => setSelectedSlide(img)}
                  >
                    <img
                      src={img}
                      alt={`Slide ${idx}`}
                      style={{
                        height: "100vh",
                        width: "auto",
                      }}
                    />
                  </div>
                ))}
          </Carousel>
        </div>

        {selectedSlide && (
          <ModalSlides isOpen={true} onClose={() => setSelectedSlide(null)}>
            <img
              src={selectedSlide}
              style={{ width: "100%", cursor: "pointer" }}
              alt="Full View"
            />
          </ModalSlides>
        )}
      </ModalStories>
    </>
  );
};

export default Navbar;
