import React from "react";
import "./modal.css";

export const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="termsModal fixed inset-0 bg-opacity-75 flex p-10 z-50">
      <div className="p-5 rounded-lg max-h-full overflow-auto">
        <button onClick={onClose} className="absolute top-0 right-0 m-2">
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export const ModalStories = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleClose = () => {
    window.scrollTo({ top: 0 }); // Scroll to the top
    setTimeout(onClose, 300); // Delay closing by 300ms to allow for smooth scrolling
    // setSelectedImage(null); // Reset modal
  };

  return (
    <div className="termsModal fixed inset-0 bg-opacity-75 flex z-9999">
      <div className="rounded-lg" style={{ margin: "auto" }}>
        <button onClick={handleClose} className="absolute top-0 right-0 m-2">
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export const ModalSlides = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-75 flex z-50" onClick={onClose}>
      <div
        className="storySlide overflow-auto max-h-full"
        style={{ width: "100%", height: "100vh" }}
      >
        {children}
      </div>
    </div>
  );
};
