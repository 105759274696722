import { useEffect, useState, useRef } from "react";
import { Link } from "react-scroll";
import musicFile from "../assets/music/backgroundMusic.mp3";
import {
  //socials
  discordHover,
  discord,
  medium,
  mediumHover,
  twitter,
  twitterHover,
  gitbook,
  gitbookHover,
  //logo
  condensedLogo,
  //menu
  gameHover,
  game,
  pilotsHover,
  pilots,
  shipsHover,
  ships,
  team,
  teamHover,
  //music
  // soundAllOn,
  soundNoteOnHover,
  soundNoteOff,
  soundNoteOffHover,
  soundNoteOn,
  lore,
  loreHover,
  // soundNoteOffHover,
  // soundAllOff,
  // soundOffHover,
} from "../assets";
import { ModalStories, ModalSlides } from "./Modal.jsx";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./navbarMobile.css";
import "./modal.css";
import loadingIcon from "../assets/loading/loading.svg";

const importAll = (requireContext) => {
  return requireContext.keys().map(requireContext);
};

const images = importAll(
  require.context("../assets/comics/covers", false, /\.(jpe?g)$/)
);

const imagesAct1 = importAll(
  require.context("../assets/comics/Act1", false, /\.(jpe?g)$/)
);

const NavbarMobile = ({ isOpenModal, setIsOpenModal }) => {
  const [sound, setSound] = useState(false);
  const [selectedAct, setSelectedAct] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setLoading(false);
    img.src = imagesAct1[0].default; // Check the first image's loading status
  }, []);

  const handleSound = () => {
    setSound(!sound);
  };

  const handleMouseEnterGame = (event) => {
    event.target.src = gameHover;
  };
  const handleMouseLeaveGame = (event) => {
    event.target.src = game;
  };
  const handleMouseEnterPilots = (event) => {
    event.target.src = pilotsHover;
  };
  const handleMouseLeavePilots = (event) => {
    event.target.src = pilots;
  };
  const handleMouseEnterShips = (event) => {
    event.target.src = shipsHover;
  };
  const handleMouseLeaveShips = (event) => {
    event.target.src = ships;
  };
  const handleMouseEnterTeam = (event) => {
    event.target.src = teamHover;
  };
  const handleMouseLeaveTeam = (event) => {
    event.target.src = team;
  };
  const handleMouseEnterLore = (event) => {
    event.target.src = loreHover;
  };
  const handleMouseLeaveLore = (event) => {
    event.target.src = lore;
  };
  const handleMouseEnterTwitter = (event) => {
    event.target.src = twitterHover;
  };
  const handleMouseLeaveTwitter = (event) => {
    event.target.src = twitter;
  };

  const handleMouseEnterDiscord = (event) => {
    event.target.src = discordHover;
  };
  const handleMouseLeaveDiscord = (event) => {
    event.target.src = discord;
  };

  const handleMouseEnterMedium = (event) => {
    event.target.src = mediumHover;
  };
  const handleMouseLeaveMedium = (event) => {
    event.target.src = medium;
  };

  const handleMouseEnterSoundAllOn = (event) => {
    event.target.src = soundNoteOnHover;
  };
  const handleMouseLeaveSoundAllOn = (event) => {
    event.target.src = soundNoteOn;
  };

  const handleMouseEnterSoundNoteOff = (event) => {
    event.target.src = soundNoteOffHover;
  };
  const handleMouseLeaveSoundNoteOff = (event) => {
    event.target.src = soundNoteOff;
  };

  const handleMouseEnterGitbook = (event) => {
    event.target.src = gitbookHover;
  };
  const handleMouseLeaveGitbook = (event) => {
    event.target.src = gitbook;
  };

  return (
    <>
      <nav>
        {sound && <audio src={musicFile} type="audio/wav" loop autoPlay />}
        <div className="flex">
          <div className="xxs:w-[200px] xs:w-[120px]  xxs:mt-[-10px] sm:w-[120px] md:w-[140px] lg:w-[175px] lg2:w-[250px] lg2:mt-[-10px] lg2:ml-[-50px] ml-[-20px] ">
            <img src={condensedLogo} alt="logo" className="xs:ml-[-25px]" />
          </div>
          <div className="flex items-center mr-5">
            <ul className="flex xxs:py-3 xxs:mt-[25px] mr-3 ">
              <a
                href="https://twitter.com/noderunnersgg"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <img
                  src={twitter}
                  alt="twitter"
                  className="xxs:w-[48px] sm:mt-[5px] md:mt-[10px] lg:w-[25px] lg:mt-[15px] lg2:mt-[35px]"
                  onMouseEnter={handleMouseEnterTwitter}
                  onMouseLeave={handleMouseLeaveTwitter}
                />
              </a>
              <a
                href="https://medium.com/@NodeRunnersgg"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <img
                  src={medium}
                  alt="medium"
                  className="xxs:w-[48px] sm:mt-[5px] md:mt-[10px] lg:w-[25px] lg:mt-[15px] lg2:mt-[35px]"
                  onMouseEnter={handleMouseEnterMedium}
                  onMouseLeave={handleMouseLeaveMedium}
                />
              </a>
              <a
                href="https://discord.gg/noderunners"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <img
                  src={discord}
                  alt="medium"
                  className="xxs:w-[48px] sm:mt-[5px] md:mt-[10px] lg:w-[25px] lg:mt-[15px] lg2:mt-[35px]"
                  onMouseEnter={handleMouseEnterDiscord}
                  onMouseLeave={handleMouseLeaveDiscord}
                />
              </a>
              <a
                href="https://noderunners.gitbook.io/litepaper"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <img
                  src={gitbook}
                  alt="gitbook"
                  className="xxs:w-[48px] sm:mt-[5px] md:mt-[10px] lg:w-[25px] lg:mt-[15px] lg2:mt-[35px]"
                  onMouseEnter={handleMouseEnterGitbook}
                  onMouseLeave={handleMouseLeaveGitbook}
                />
              </a>

              {!sound ? (
                <>
                  <img
                    src={soundNoteOff}
                    alt="soundAllOff"
                    className="xxs:w-[42px] xs:w-[38px] sm:mt-[5px] sm:w-[48px] md:mt-[10px] md:w-[80px] lg:mt-[15px] lg2:mt-[35px]  "
                    onMouseEnter={handleMouseEnterSoundNoteOff}
                    onMouseLeave={handleMouseLeaveSoundNoteOff}
                    style={{ cursor: "pointer" }}
                    onClick={handleSound}
                  />
                </>
              ) : (
                <>
                  <img
                    src={soundNoteOn}
                    alt="soundAllOn"
                    className="xxs:w-[42px] xs:w-[38px] sm:mt-[5px] sm:w-[48px] md:mt-[10px] md:w-[80px] lg:mt-[15px] lg2:mt-[35px] "
                    onMouseEnter={handleMouseEnterSoundAllOn}
                    onMouseLeave={handleMouseLeaveSoundAllOn}
                    style={{ cursor: "pointer" }}
                    onClick={handleSound}
                  />
                </>
              )}
            </ul>
          </div>
        </div>
        <div className="flex justify-center ">
          <ul className="flex xxs:py-5 xs:ml-[-50px]">
            <Link to="game" spy={true} smooth={true} offset={50} duration={500}>
              <img
                src={game}
                className="xxs:w-[120px] xs:w-[70px] sm:w-[90px] md:w-[110px] sm:mt-[5px] md:mt-[10px] lg:w-[130px] lg:mt-[15px] lg2:w-[160px] lg2:mt-[35px]  "
                alt="game"
                onMouseEnter={handleMouseEnterGame}
                onMouseLeave={handleMouseLeaveGame}
              />
            </Link>
            <Link
              to="pilots"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              <img
                src={pilots}
                alt="pilots"
                className="xxs:w-[143px] sm:w-[90px] sm:mt-[5px] md:w-[110px] md:mt-[10px] lg:w-[130px] lg:mt-[15px] lg2:w-[170px] lg2:mt-[35px]  "
                onMouseEnter={handleMouseEnterPilots}
                onMouseLeave={handleMouseLeavePilots}
              />
            </Link>
            <Link to="ships" spy={true} smooth={true} offset={0} duration={500}>
              <img
                src={ships}
                alt="ships"
                className="xxs:w-[120px] sm:w-[90px] sm:mt-[5px] md:w-[110px] md:mt-[10px] lg:w-[130px] lg:mt-[15px] lg2:w-[160px] lg2:mt-[35px]  "
                onMouseEnter={handleMouseEnterShips}
                onMouseLeave={handleMouseLeaveShips}
              />
            </Link>
          </ul>
        </div>
        <div className="flex justify-center ">
          <Link to="team" spy={true} smooth={true} duration={500}>
            <img
              src={team}
              className="xxs:w-[120px] xxs:mt-[-20px] xs:w-[70px] sm:w-[90px] md:w-[110px] sm:mt-[5px] md:mt-[10px] lg:w-[130px] lg:mt-[15px] lg2:w-[160px] lg2:mt-[35px]  "
              alt="team"
              onMouseEnter={handleMouseEnterTeam}
              onMouseLeave={handleMouseLeaveTeam}
            />
          </Link>

          {/* Lore */}
          <img
            src={lore}
            className="xxs:w-[120px] xxs:mt-[-20px] xs:w-[70px] sm:w-[90px] md:w-[110px] sm:mt-[5px] md:mt-[10px] lg:w-[130px] lg:mt-[15px] lg2:w-[160px] lg2:mt-[35px]  "
            alt="team"
            onMouseEnter={handleMouseEnterLore}
            onMouseLeave={handleMouseLeaveLore}
            onClick={() => setIsOpenModal(true)}
          />
        </div>
      </nav>

      <ModalStories
        // className="no-scroll"
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          setSelectedAct(false);
          setCurrentIndex(0);
        }}
      >
        <div>
          <Carousel
            useKeyboardArrows={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={!selectedAct ? false : true}
            showArrows={selectedAct && true}
            // centerMode={!selectedAct && true}
            swipeable={selectedAct && false}
            selectedItem={currentIndex}
          >
            {imagesAct1.map((img, idx) => (
              <div
                key={idx}
                onClick={() => {
                  if (currentIndex === 0) setCurrentIndex(currentIndex + 1);
                  setSelectedAct(true);
                }}
              >
                <img
                  src={img}
                  alt={`Slide ${idx}`}
                  style={{
                    height: "auto",
                    width: "100vw",
                  }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </ModalStories>
    </>
  );
};

export default NavbarMobile;

// {!selectedAct
//   ? images.map((img, idx) => (
//       <div
//         // className="mx-2"
//         key={idx}
//         onClick={() => setSelectedAct(true)}
//         style={{
//           position: "relative", // To make sure the image positions correctly within
//           width: "100vw", // Full width of the viewport
//           height: `calc(100vw * ${3508 / 2480})`,
//         }}
//       >
//         <img
//           src={img}
//           alt={`Slide ${idx}`}
//           style={{
//             height: "auto",
//             width: "100vw",
//           }}
//         />
//       </div>
//     ))
//   : imagesAct1.map((img, idx) => (
//       <div key={idx}>
//         <img
//           src={img}
//           alt={`Slide ${idx}`}
//           style={{
//             height: "auto",
//             width: "100vw",
//           }}
//         />
//       </div>
//     ))}
