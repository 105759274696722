import {
  // condensedLogo,
  // socials
  discordHover,
  discord,
  mediumHover,
  medium,
  twitterHover,
  twitter,
  // nav states
  gameHover,
  game,
  pilotsHover,
  pilots,
  shipsHover,
  ships,
  // sound states
  // soundOffHover,
  // soundOff,
  // soundBorderHover,
  // soundBorder,
  // soundNoteOffHover,
  // soundNoteOff,
  // soundNoteOn,
  // soundNoteOnHover,
  // soundWordOffHover,
  // soundWordOff,
  // soundWordOn,
  // soundWordOnHover,
  // soundAllOn,
  // submit states
  // submitActive,
  // submitInactive,
  //desktop
  pilotsDesktopAlien,
  pilotsDesktopDemon,
  pilotsDesktopHuman,
  pilotsDesktopOrc,
  pilotsDesktopIlluminated,
  // mobile
  pilotsMobileDemon,
  pilotsMobileAlien,
  pilotsMobileHuman,
  pilotsMobileOrc,
  pilotsMobileIlluminated,
} from '../assets';

export const PilotsDesktop = [
  {
    id: "illuminated",
    title: "Illuminated",
    img: pilotsDesktopIlluminated,
  },

  {
    id: "human",
    title: "Human",
    img: pilotsDesktopHuman,
  },

  {
    id: "alien",
    title: "Alien",
    img: pilotsDesktopAlien,
  },

  {
    id: "orc",
    title: "Orc",
    img: pilotsDesktopOrc,
  },

  {
    id: "demon",
    title: "Demon",
    img: pilotsDesktopDemon,
  },

];

export const PilotsMobile = [
  {
    id: "illuminated",
    title: "Illuminated",
    img: pilotsMobileIlluminated,
  },

  {
    id: "human",
    title: "Human",
    img: pilotsMobileHuman,
  },

  {
    id: "alien",
    title: "Alien",
    img: pilotsMobileAlien,
  },

  {
    id: "orc",
    title: "Orc",
    img: pilotsMobileOrc,
  },

  {
    id: "demon",
    title: "Demon",
    img: pilotsMobileDemon,
  },

];


export const socials = [
  {
    id: "discord",
    title: "Discord",
    img: discord,
  },

  {
    id: "medium",
    title: "Medium",
    img: medium,
  },

  {
    id: "twitter",
    title: "Twitter",
    img: twitter,
  },
];

export const socialsHover = [
  {
    id: "discord",
    title: "Discord",
    img: discordHover,
  },

  {
    id: "medium",
    title: "Medium",
    img: mediumHover,
  },

  {
    id: "twitter",
    title: "Twitter",
    img: twitterHover,
  },

];

export const menuButtons = [

  {
    id: "game",
    title: "Game",
    img: game,
  },

  {
    id: "pilots",
    title: "Pilots",
    img: pilots,
  },

  {
    id: "ships",
    title: "Ships",
    img: ships,
  },

];

export const menuButtonsHover = [

  {
    id: "game",
    title: "Game",
    img: gameHover,

  },

  {
    id: "pilots",
    title: "Pilots",
    img: pilotsHover,
  },

  {
    id: "ships",
    title: "Ships",
    img: shipsHover,
  },

]

