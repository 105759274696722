import React from "react";
import { PilotsMobile } from "./constants";

const PilotCardsMobile = () => {
  return (
    <div className="mt-[80px]">
      <div className="flex justify-center items-center">
        <ul className="z-20">
          {PilotsMobile.map((pilot, index) => {
            return (
              <li
                key={pilot.id}
                className="flex flex-col justify-center items-center"
              >
                <img
                  src={pilot.img}
                  alt={pilot.title}
                  className="mb-5"
                  loading="lazy"
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default PilotCardsMobile;
