import React from "react";
import { nebulaPNG, shipHero, headlineShips } from "../assets";
import "./shipsHero.css";

const ShipsHero = () => {
  return (
    <div className="relative">
      <div className=" md:mt-[-300px] xl:mt-[0px]">
        <div className=" flex my-5  ">
          <div className=" flex xs:mt-[200px] ">
            <img
              src={nebulaPNG}
              alt="nebula"
              className="nebula z-50
              xs:ml-[-80px] sm:ml-[-100px] md:ml-[-120px] lg:ml-[-150px] lg2:ml-[-180px] xl:ml-[-220px] xxl:ml-[-250px]"
              // loading="lazy"
            />
          </div>
          <div className="z-50 xs:mt-[200px] xxs:ml-[55px] xs:ml-[55px] xl:mt-[100px]">
            <img
              src={shipHero}
              alt="ships"
              className="scale-150 "
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="z-50 xxs:mt-[-50px] ">
        <div className="z-50 flex justify-center xl:mt-[-300px]">
          <img
            src={headlineShips}
            alt="ships"
            className="scale-50 xxs:scale-75 xxl:scale-100 z-50 xxs:mt-[-50px]"
            loading="lazy"
          />
        </div>
        <div className="z-60 xxs:px-8 mt-[-20px]">
          <p className="ships-text-container relative z-50 text-center items-center justify-center">
            <span
              className="z-50
                  xxs:text-[14px] xs:text-[16px] sm:text-[18px] md:text-[18px] lg:text-[18px] 
                  xl:text-[24px] xxl:text-[30px] "
            >
              There are 4 classes of ships, each class with 8 models
              <br />
              that specialize in different actions like reconnaissance,
              <br />
              trading, hunting, and heavy battle.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShipsHero;
