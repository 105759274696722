import React from "react";
import header from "../assets/yourTeamSection/yourTeamHeader.png";
import august from "../assets/yourTeamSection/august-solo.png";
import mark from "../assets/yourTeamSection/mark-solo.png";
import phu from "../assets/yourTeamSection/phu-solo.png";
import zach from "../assets/yourTeamSection/zach-solo.png";

const teamData = [
  {
    image: august,
    text1: "August Hogan III",
    text2: "Chief Executive Officer",
    text3: "@goodguyaugust",
  },
  {
    image: zach,
    text1: "Zach Burns",
    text2: "Chief Financial Officer",
    text3: "@burnsycrypto",
  },
  {
    image: phu,
    text1: "Phu Ngo",
    text2: "Tech Lead",
    text3: "@phu_ngo",
  },
  {
    image: mark,
    text1: "Mark Keele",
    text2: "Creative Director",
    text3: "@dabblindad",
  },
];

const YourTeamSection = () => {
  return (
    <>
      {/* SMALL SCREEN */}
      <div
        className="relative md:hidden xxs:mt-[50px]
        xxs:text-[12px] xs:text-[15px] sm:text-[15px] md:text-[16px] lg:text-[18px] xl:text-[24px]"
        style={{ zIndex: 1 }}
      >
        <div className="flex justify-center align-items">
          <img
            src={header}
            alt="your-team"
            className="xxs:scale-65 xs:scale-65 xxl:scale-100 relative"
            id="yourTeamDesktop"
          />
        </div>
        <div
          className="flex justify-center items-center mx-8"
          style={{ color: "white" }}
        >
          {teamData.slice(0, 2).map((el, i) => {
            return (
              <div key={el.text1}>
                <img src={el.image} alt={i} id={i} />
                <div className="flex flex-col justify-center items-center xxs:mt-[-10px] sm:mt-[-25px]">
                  <p style={{ color: "#e8c2f2", fontWeight: "bold" }}>
                    {el.text1}
                  </p>
                  <p>{el.text2}</p>
                  <p>{el.text3}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="flex justify-center items-center mx-8"
          style={{ color: "white" }}
        >
          {teamData.slice(2, 4).map((el, i) => {
            return (
              <div key={el.text1}>
                <img src={el.image} alt={i} id={i} />
                <div className="flex flex-col justify-center items-center xxs:mt-[-10px] sm:mt-[-25px]">
                  <p style={{ color: "#e8c2f2", fontWeight: "bold" }}>
                    {el.text1}
                  </p>
                  <p>{el.text2}</p>
                  <p>{el.text3}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* MID-LARGE SCREEN */}
      <div
        className="hidden md:block xxs:text-[14px] xs:text-[15px] sm:text-[15px] md:text-[16px]
            lg:text-[18px] xl:text-[24px] xxl:text-[30px]"
      >
        <div className="flex justify-center align-items">
          <img
            src={header}
            alt="your-team"
            className="xxs:scale-65 xxl:scale-100 relative"
            id="yourTeamDesktop"
          />
        </div>
        <div
          className="flex justify-center items-center"
          style={{ color: "white" }}
        >
          {teamData.map((el, i) => {
            return (
              <div key={el.text1}>
                <img src={el.image} alt={i} id={i} />
                <div className="flex flex-col justify-center items-center mt-[-25px]">
                  <p style={{ color: "#e8c2f2", fontWeight: "bold" }}>
                    {el.text1}
                  </p>
                  <p>{el.text2}</p>
                  <p>{el.text3}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
// #dbb3e6
export default YourTeamSection;
