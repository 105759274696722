import "../App.css";
import PartnersSection from "./PartnersSection.jsx";
import YourTeamSection from "./YourTeamSection.jsx";
import {
  MyParticles,
  Hero,
  Footer,
  ImageHorizon,
  ShipsHero,
  PilotCardsMobile,
  NavbarMobile,
  ShipsMobile,
  PilotsMobile,
  ConceptSection,
} from "./index.js";
import ComingSoon from "./ComingSoon.jsx";
import "./navbarMobile.css";
import { useState } from "react";

const MainContentMobile = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      {!isOpenModal ? (
        <div>
          <div>
            <div className="z-30 nav-container">
              <div className="xs:hidden xxs:block">
                <NavbarMobile
                  isOpenModal={isOpenModal}
                  setIsOpenModal={setIsOpenModal}
                />
              </div>
              {/* Banner */}
              <div
                className="info-banner-mobile xxs:text-[14px] xs:text-[13px] sm:text-[18px] 
            md:text-[20px] lg:text-[24px] xl:text-[30px] xxl:text-[40px] z-10"
              >
                <div
                  className="banner-text-container xxs:text-[15px] xs:text-[13px] sm:text-[18px] 
              md:text-[20px] lg:text-[24px] xl:text-[30px] xxl:text-[40px]"
                >
                  <p>We're minted out. Join our Discord to stay up to date.</p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="relative justify-center align-center "
            style={{ zIndex: 50 }}
          >
            <div className="hero-container">
              <div className="z-20 relative mt-[150px]">
                <Hero />
              </div>
              <ConceptSection />
              <div className="z-1">
                <ImageHorizon />
              </div>
            </div>
          </div>
          <div className="relative z-50">
            {/* <div className="z-20"> */}
            <div>
              <div className="xs:hidden xxs:block z-0" id="pilots">
                <PilotsMobile />
              </div>
              <div className="md:hidden z-0">
                <PilotCardsMobile />
              </div>
            </div>
            <div className="" id="ships">
              <ShipsHero />
              <div>
                <div className="xs:hidden xxs:block">
                  <ShipsMobile />
                </div>
              </div>
            </div>
            <div className="xs:hidden xxs:block" id="team">
              <YourTeamSection />
            </div>
            <PartnersSection />
            <ComingSoon />
            <div>
              <Footer />
            </div>
            {/* </div> */}
          </div>
          <MyParticles />
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            background: "black",
            width: "100%",
            zIndex: "0",
            scrollbarWidth: "100",
          }}
        >
          <div>
            <div className="z-30 nav-container">
              <div className="xs:hidden xxs:block">
                <NavbarMobile
                  isOpenModal={isOpenModal}
                  setIsOpenModal={setIsOpenModal}
                />
              </div>
              {/* Banner */}
              <div
                className="info-banner-mobile xxs:text-[14px] xs:text-[13px] sm:text-[18px] 
            md:text-[20px] lg:text-[24px] xl:text-[30px] xxl:text-[40px] z-10"
              >
                <div
                  className="banner-text-container xxs:text-[15px] xs:text-[13px] sm:text-[18px] 
              md:text-[20px] lg:text-[24px] xl:text-[30px] xxl:text-[40px]"
                >
                  <p>We're minted out. Join our Discord to stay up to date.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainContentMobile;
