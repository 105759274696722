import React, { useEffect, useState } from "react";
import {
  DNA,
  face,
  dividerBottom,
  headlinePilots,
  dividerTop,
} from "../assets";
import "./glitchImagePilot.css";
import { Parallax } from "react-scroll-parallax";
import "./pilots.css";
// import vid from "../assets/glitchVideos/pilot-final.mp4";
import backg from "../assets/backgrounds/P1.jpg";
import glitch1 from "../assets/backgrounds/P2.jpg";
import glitch2 from "../assets/backgrounds/P3.jpg";
import glitch3 from "../assets/backgrounds/P4.jpg";
import glitch4 from "../assets/backgrounds/P5.jpg";

const glitchImages = [glitch1, glitch2, glitch3, glitch4];

const Pilots = () => {
  const [glitchIndex, setGlitchIndex] = useState(4);
  const [runGlitch, setRunGlitch] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRunGlitch((v) => {
        return v === 4 ? 0 : v + 1;
      });
    }, 3500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (runGlitch !== 0) {
      const timeouts = [
        [3, 0],
        [2, 125],
        [4, 250],
        [0, 625],
        [1, 750],
        [0, 875],
        [4, 1000],
        [0, 1125],
        [3, 1250],
        [4, 1375],
      ];

      timeouts.forEach(([index, time]) => {
        setTimeout(() => {
          setGlitchIndex(index);
        }, time);
      });
    }
  }, [runGlitch]);

  return (
    <div className="mt-[100px]">
      <Parallax translateY={[0, 50]} className="relative z-50">
        <div className=" flex justify-center">
          <img
            src={headlinePilots}
            alt="pilots"
            className="mt-[-200px] xxs:scale-75 xxl:scale-100 relative z-50 xxs:mt-[-50px]"
            id="pilotsDesktop"
          />
        </div>
        <div className="mt-[-125px] z-50">
          <img
            src={dividerTop}
            alt="pilots"
            className="w-full h-full bg-transparent z-50 xxs:mt-[70px]"
          />
        </div>
        <div className="flex justify-center align-center z-50 relative xs:mx-[30px] md:mx-[50px] xl:mx-[200px]">
          <div className="flex row justify-evenly items-center">
            <div className=" items-center z-20 xxs:opacity-50 xs:opacity-100">
              <img src={DNA} alt="DNA" className="xxl:w-[400px]" />
            </div>
            <p
              className="pilot-text-container text-start relative z-30 mx-5 mt-5 mb-5 text-sm 
            xxs:text-[8px] xs:text-[14px] sm:text-[12px] md:text-[16px] lg:text-[18px] xl:text-[24px]
            xxl:text-[30px] xs:block xxs:hidden"
            >
              Genesis pilots consist of five origins:
              <br />
              <strong>
                {" "}
                Baleforged, Nyx, Abyssians, Eryines, and Illuminated.
              </strong>
              <br />
              <br />
              Each pilot’s origin correlates with a unique stat boost to every
              ship under that pilot’s control.
            </p>
          </div>
          <div className="flex items-center z-20 xxs:opacity-50 xs:opacity-100">
            <img src={face} alt="face" className="xxl:w-[400px]" />
          </div>
        </div>
        <div>
          <img
            src={dividerBottom}
            alt="dividerBottom"
            className="w-full h-full z-20 "
          />
        </div>
      </Parallax>
      <div
        className="flex justify-center z-5 xs:mt-[0px] scale-150 items-center xxs:mt-[150px] 
        xs:mt-[150px] sm:mt-[250px] md:mt-[300px] lg:mt-[300px] lg2:mt-[400px] xl:mt-[500px]"
      >
        <div className="glitch-container">
          <img className="main-image" src={backg} alt="Main" />
          {glitchIndex !== 4 && (
            <div className="glitch-images">
              <img src={glitchImages[glitchIndex]} />
            </div>
          )}
        </div>
        {/* <video autoPlay loop muted playsInline>
          <source src={vid} type="video/mp4" />
        </video> */}
      </div>
    </div>
  );
};

export default Pilots;
