import React from "react";
import pic1 from "../assets/concepts-section/1.png";
import pic2 from "../assets/concepts-section/2.png";
import pic3 from "../assets/concepts-section/3.png";
import pic4 from "../assets/concepts-section/4.png";
import "./conceptSection.css";

const conceptsData = [
  {
    id: 1,
    image: pic1,
    title: "Explore the Universe ::",
    text: (
      <>
        Our strategic navigation system is{" "}
        <span style={{ whiteSpace: "nowrap" }}>one-of-a-kind</span> in gaming.
        Pilots can move across the universe by navigating from node to node
        similar to a physical game piece across a game board. The NodeRunners
        universe is vast, containing 40 planets and 3 space stations spread
        across three different zones.
      </>
    ),
    className: "concept2-text-container",
  },
  {
    id: 2,
    image: pic2,
    title: "Player-determined outcomes ::",
    text: (
      <>
        The fate of the universe is determined entirely by the community. Pilots
        will band together to create fully-customizable guilds. Guild leaders
        can appoint officers, enlist, demote, kick and determine guild bank
        permissions.
      </>
    ),
    className: "concept-text-container",
  },
  {
    id: 3,
    image: pic3,
    title: "Game your way ::",
    text: (
      <>
        The NodeRunners universe has something for every pilot, no matter the
        play style. Players can battle pilots from other guilds, trade
        resources, mine asteroids for valuable resources, bounty hunt, run
        contraband contracts, or act as diplomats. There are also high stakes
        mini-games available when pilots dock on a planet.
      </>
    ),
    className: "concept2-text-container",
  },
  {
    id: 4,
    image: pic4,
    title: "Earn rewards for playing ::",
    text: (
      <>
        Players will earn rewards simply by playing the game. Players can also
        own ultra-rare digital assets called Satellites that they can deploy to
        a planet to claim a portion of all of that planet’s economic activity
        for each hour the satellite is deployed. This system has aligned
        incentives, as each satellite owner’s guild will also receive rewards
        for every hour that the satellite is deployed - unless another guild
        destroys it and deploys their own. Players can use the reward currency
        to buy or upgrade their assets.
      </>
    ),
    className: "concept-text-container",
  },
];

const ConceptSection = () => {
  return (
    <>
      {/* SMALL SCREEN */}
      <div
        className="md:hidden xxs:mt-[50px] xs:mt-[-50px]
        xxs:text-[14px] xs:text-[15px] sm:text-[15px] md:text-[16px] lg:text-[18px] xl:text-[24px]"
        style={{ zIndex: 500 }}
      >
        {conceptsData.slice(0, 4).map((el, i) => {
          return (
            <div className="flex flex-col justify-center items-center" key={i}>
              <div style={{ width: "70%" }}>
                <img src={el.image} alt={el.id} />
              </div>
              <div className={el.className}>
                <p className="text-center z-30 ">{el.title}</p>
                <p className="text-center z-30 py-2 xxs:px-10 xs:px-32 sm:px-44">
                  {el.text}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      {/* MID-LARGE SCREEN */}
      <div
        className="hidden md:block xxs:text-[14px] xs:text-[15px] sm:text-[15px] md:text-[16px] 
            lg:text-[18px] xl:text-[24px] xxl:text-[30px]"
      >
        {conceptsData.slice(0, 3).map((el, i) => {
          return (
            <div
              className="flex justify-center items-center xl:px-20 xxl:px-2"
              key={i}
            >
              {el.id % 2 !== 0 && (
                <div style={{ flex: 1 }}>
                  <img src={el.image} alt={el.id} />
                </div>
              )}
              <div className={el.className} style={{ flex: 1 }}>
                <p className="text-center">{el.title}</p>
                <p className="text-center sm:py-2 xxl:py-4 md:px-12 lg:px-16 lg2:px-20 xl:px-20 xxl:px-40">
                  {el.text}
                </p>
              </div>
              {el.id % 2 === 0 && (
                <div style={{ flex: 1 }}>
                  <img src={el.image} alt={el.id} />
                </div>
              )}
            </div>
          );
        })}
        {conceptsData.slice(3, 4).map((el, i) => {
          return (
            <div
              className="flex justify-center items-center xl:px-20 xxl:px-2 lg:mt-[-50px] xl:mt-[-80px]"
              key={i}
            >
              <div className={el.className} style={{ flex: 1 }}>
                <p className="text-center">{el.title}</p>
                <p className="text-center sm:py-2 xxl:py-4 md:px-12 lg:px-16 lg2:px-20 xl:px-8 xxl:px-30">
                  {el.text}
                </p>
              </div>
              <div style={{ flex: 1 }}>
                <img src={el.image} alt={el.id} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ConceptSection;
