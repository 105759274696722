import React from "react";
import { PilotsDesktop } from "./constants";
import { landscape2 } from "../assets";

const PilotCards = () => {
  return (
    <>
      <div className="flex justify-center items-center xxs:mt-[150px] md:mt-[400px] lg:mt-[800px] lg2:mt-[800px] xxl:mt-[1000px] z-10">
        <img
          src={landscape2}
          alt="landscape"
          className="z-[-1] xs:mt-[0px] scale-150"
        />
        <ul
          className="z-30 absolute xs:mt-[-20px] sm:mt-[-100px] md:mt-[-500px] lg:mt-[-1000px] lg2:mt-[-1000px] 
        xl:mt-[-800px] xxl:mt-[-1000px]"
        >
          {PilotsDesktop.map((pilot, index) => {
            const listItemStyle = {
              marginRight: index % 2 === 0 ? "150px" : "0",
              marginLeft: index % 2 !== 0 ? "150px" : "0",
            };

            return (
              <li
                key={pilot.id}
                className="flex flex-col justify-center items-center z-10 "
                style={listItemStyle}
              >
                <img
                  src={pilot.img}
                  alt={pilot.title}
                  className="xxs:mt-[-120px] xs:mt-[-10px] sm:mt-[-40px] md:mt-[-80px] lg:mt-[-80px] lg2:mt-[-90px] 
                  xl:mt-[-100px] xxl:mt-[-40px]
                  sm:scale-75 lg:scale-80 lg2:scale-80 xl:scale-80 xxl:scale-100"
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default PilotCards;
