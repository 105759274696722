import { useDidMount, useWillUnmount } from "beautiful-react-hooks";
import loading from "../assets/loading/mythos-loading.gif";
export default function LogoLoader() {
  useDidMount(() => {
    document.documentElement.style.overflow = "hidden";
  });

  useWillUnmount(() => {
    document.documentElement.style.overflow = "auto";
  });

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "black",
        zIndex: 99999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={loading} alt="Loading" width="200px" />
    </div>
  );
}
